@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;600;700&display=swap"); */

/* * {
  cursor: none;
} */

body {
  margin: 0;
  font-family: "Fira Sans", "Raleway", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  /* position: relative; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.transparent-text {
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate(0%, 0%);
  opacity: 0.7;
}

.hex {
  -webkit-clip-path: polygon(
    25% 5%,
    75% 5%,
    100% 50%,
    75% 95%,
    25% 95%,
    0% 50%
  );
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
}

.about-svg-size {
  @apply text-5xl sm:text-6xl text-white stroke-white stroke-0;
}

/* modal */

.modal {
  /* width: clamp(50%, 700px, 90%); */
  @apply bg-white mx-auto rounded-none md:rounded flex items-center flex-col w-full h-full lg:max-w-4xl md:max-h-[80%] lg:max-h-[70%];
}

::-webkit-scrollbar {
  display: none;
}
